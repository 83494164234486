import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
	cast_create,
	cast_delete,
	cast_update,
	all_cast_list,
} from "../../../actions/Masters/cast";
import { useDispatch, useSelector } from "react-redux";
import Import from "../../utils/Import";
import { useLocation ,useNavigate } from "react-router-dom";
import Export from "../../utils/Export";
import Reload from "../../utils/Reload";
import { all_content_advisory_list, content_advisory_create, content_advisory_delete } from "../../../actions/Masters/advisory";
export default function ContentAdvisory() {
	const user = useSelector((state) => state.layout.profile)
	const dispatch = useDispatch();
	const rights = useSelector((state) => state.layout.right)
	console.log(rights,"riftt")
	const location = useLocation();
	const navigate = useNavigate()
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
		} else {
			setView("view_all");
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[3]
	useEffect(() => {
		setView(path != "ContentAdvisory" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditContentAdvisory");
	}, [location]);
	const tempTableData = {
		tableTitle: "Advisory",
		deleteRecord: content_advisory_delete,
		// updateRecord: cast_update,
		onDeleteText: "Are you sure to delete?",
		deleteAccess: rights?.["Masters"]?.["delete"] == "true",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "content_advisory",	
				label: "Advisory",
			},
			// {
			// 	id: "edit",
			// 	label: "Update",
			// 	access: rights?.["Masters"]?.["edit"] == "true",
			// },
		],
		tableBody: [
			{
				id: 0,
				cast: "Movie",
				image: "Landscape",
				cast_type: "Active",
				edit: 0,
			},
		],
		filterColumn: [],
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Content Advisory",
			name: "content_advisory",
			required: true,
		},
		
		{
			id: "2",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];

	const Advisory = useSelector((state) => state.masters.advisory);
	useEffect(() => {
		dispatch(all_content_advisory_list());
	}, []);
	useMemo(() => {
		if (Advisory?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = Advisory?.data;
			setTableData({ ...temp });
		}
	}, [Advisory]);

	const formTitle = isEdit ? "Edit Advisory" : "Create Advisory";
	const message = useSelector((state) => state.layout.message);
	console.log(message,"message")
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		

		if (isEdit) {
			dispatch(cast_update(data));
		} else {
			dispatch(content_advisory_create(data));
		}
	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);
			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};
			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_content_advisory_list());
				navigate("/Masters/ContentAdvisory/ContentAdvisory/", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		} else {
			setForm({ ...form, cast_image: form?.tempcast_image });
		}
	}, [message]);
	return (
		<>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				access={rights?.["Masters"]?.["create"] == "true"}
				view={view}
				isEdit={isEdit}
				create_new={"/Masters/ContentAdvisory/CreateContentAdvisory"}
				view_all={"/Masters/ContentAdvisory/ContentAdvisory"}
				form={form}
				reload={<Reload isClubed={true} />}
				import_excel={<Import isClubed={true} access={rights?.["Masters"]?.["import_excel"] == "true"} />}
				export_excel={
					<Export
						fileName={"Casts"}
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						access={rights?.["Masters"]?.["export_excel"] == "true"}
						headings={tableData.tableHead.map((value) => value.label)}
					/>
				}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Masters/ContentAdvisory/EditContentAdvisory"}

				/>
			)}
		</>
	);
}
