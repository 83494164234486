import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import  DashboardIcon  from "./../../../images/dashboard_SideMenu.png";
import  WebSeriesIcon  from "./../../../images/Webseries_SideMenu.png";
import  WatchhoursIcon  from "./../../../images/watchhours_SideMenu.png";
import  TransactionIcon  from "./../../../images/Transactions_SideMenu.png";
import  SubscriptionIcon  from "./../../../images/subscription_SideMenu.png";
import  MovieIcon  from "./../../../images/Movie_SideMenu.png";
import ShortSeriesIcon from "./../../../images/Short_Stories.png"
import  MastersIcon  from "./../../../images/Masters_SideMenu.png";
import  CustomersIcon  from "./../../../images/customers_SideMenu.png";
import  ComplaintIcon  from "./../../../images/Complaint_SideMenu.png";
import SettingIcon from './../../../images/Setting_SideMenu.png'
import SeriesIcon from './../../../images/Series_SideMenu.png'
import SeasonIcon from './../../../images/Season_SideMenu.png'
import WebEpisodeIcon from './../../../images/WebEpisode_SideMenu.png'
import SliderBannerIcon from './../../../images/SliderBanner_SideMenu.png'
import ProducerIcon from "./../../../images/Distributors_SideMenu.png"
import { useSelector } from "react-redux";
export const SidebarData= () => { 
  const rights = useSelector((state) => state.layout.right);
  const role = useSelector((state) => state.layout.role);

// console.log(role ,"newRole")
  return [
  {
    title: "Dashboard",
    path: "/Dashboard/Dashboard",
    icon: <img src={DashboardIcon} height={"27px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: "true",
    
  },
 
  {
    title: "Videos",
    path: "/Movie/Movie",
    icon: <img src={MovieIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: role != "Distributor" ?   rights?.["Movie"]?.["view"] : "true",
  },
  
  role != "Distributor" &&
  {
    title: "Web Series",
    path: "/Series/",
    icon: <img src={SeriesIcon} height={"30px"} />,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: "true",
    subNav: [
      {
        title: "Series",
        path: "/Series/Series",
        access: role != "Distributor" ? rights?.["Series"]?.["view"] : "true",
      },
      {
        title: "Season",
        path: "/Season/Season/",
        access: role !=  "Distributor" ?  rights?.["Season"]?.["view"] : "true",
      },
      {
        title: "Web Series Episode",
        path: "/WebEpisode/WebEpisode/",
        access: role !=  "Distributor" ? rights?.["Episode"]?.["view"] : "true",
      },
      {
        title: "Super Short Series",
        path: "/SSEpisode/SSEpisode/",
        access: role !=  "Distributor" ? rights?.["Episode"]?.["view"] : "true",
      },
    ].filter((e) => e),
  },
  role == "Distributor" &&  {
    title: "Series",
    path: "/Series/Series",
    icon: <img src={SeriesIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: role != "Distributor" ? rights?.["Series"]?.["view"] : "true",
  },
  role == "Distributor" && {
    title: "Season",
    path: "/Season/Season",
    icon: <img src={SeasonIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: role !=  "Distributor" ?  rights?.["Season"]?.["view"] : "true",
  },
  role == "Distributor" &&{
    title: "Web Series Episode",
    path: "/WebEpisode/WebEpisode",
    icon: <img src={WebEpisodeIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: role !=  "Distributor" ? rights?.["Episode"]?.["view"] : "true",
  },
  role == "Distributor" &&{
    title: "Super Short Series",
    path: "/SSEpisode/SSEpisode",
    icon: <img src={ShortSeriesIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: role !=  "Distributor" ? rights?.["Episode"]?.["view"] : "true",
  
  },

  
  role != "Distributor" && {
    title: "Slider Banner",
    path: "/SliderBanner/SliderBanner",
    icon: <img src={SliderBannerIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access:  rights?.["Slider Banner"]?.["view"],
  },
  role != "Distributor" && {
    title: "Customers",
    path: "/Customer/Customer",
    icon: <img src={CustomersIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Customers"]?.["view"],
  },
  role != "Distributor" && {
    title: "Collaborator",
    path: "/Collaborator/Collaborator",
    icon: <img src={ProducerIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Collaborator"]?.["view"],
  },
  role != "Distributor" && {
    title: "Submissions",
    path: "/DistributorsFormData/DistributorsFormData",
    icon: <img src={ProducerIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Submissions"]?.["view"],
    
  },
  role != "Distributor" && {
    title: "Film Festival",
    path: "/FilmFestivalFormData/",
    icon: <img src={ProducerIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Submissions"]?.["view"],
    subNav: [
      {
        title: "Pending Form",
        path: "/FilmFestivalPendingData/FilmFestivalPendingData",
        access:  rights?.["Submissions"]?.["view"],
      },
      {
        title: "Nomenited Form",
        path: "/FilmFestivalNominatedData/FilmFestivalNominatedData",
        access: rights?.["Submissions"]?.["view"],
      },
      {
        title: "Short List Form",
        path: "/FilmFestivalShortListData/FilmFestivalShortListData",
        access:  rights?.["Submissions"]?.["view"],
      },
      {
        title: "Rejected Form",
        path: "/FilmFestivalRejectedData/FilmFestivalRejectedData",
        access: rights?.["Submissions"]?.["view"],
      },
      
    ]
    
  },
  role != "Distributor" && {
    title: "All Transactions",
    path: "/AllTransaction/AllTransaction",
    icon:<img src={TransactionIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Transactions"]?.["view"],
   
  },
  role != "Distributor" && {
    title: "Revenue Report",
    path: "/reports/reports",
    icon:<img src={TransactionIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Reports"]?.["view"],
   
  },

  // {
  //   title: "Watch-hours",
  //   path: "/WatchHours/WatchHours",
  //   icon:<img src={WatchhoursIcon} height={"30px"} width={"30px"} style={{background:"white",padding:"2px",borderRadius:"10px"}}/>,
  //   iconClosed: <KeyboardArrowRightIcon />,
  //   iconOpened: <KeyboardArrowDownIcon />,
    
  // },
 
  role != "Distributor" && {
    title: "Complaints",
    path: "/Complaint/Complaint",
    icon:<img src={ComplaintIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Complaints"]?.["view"],
    
  },
  
  
 
  
  


  role != "Distributor" && {
    title: "Masters",
    path: "/Masters/",
    icon:<img src={MastersIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Masters"]?.["view"],

    subNav: [
 
    
      {
        title: "Category",
        path: "/Masters/Category/Category",
        access: rights?.["Masters"]?.["view"],
      },
      {
        title: "Sub Category",
        path: "/Masters/SubCategory/SubCategory",
        access: rights?.["Masters"]?.["view"],
      },
      {
        title: "Language",
        path: "/Masters/Language/Language",
        access: rights?.["Masters"]?.["view"],
      },
      {
        title: "Genre",
        path: "/Masters/Genre/Genre",
        access: rights?.["Masters"]?.["view"],
      },
      {
        title: "Cast",
        path: "/Masters/Cast/Cast",
        access: rights?.["Masters"]?.["view"],
      },
      {
        title: "Advisory",
        path: "/Masters/ContentAdvisory/ContentAdvisory",
        access: rights?.["Masters"]?.["view"],
      },
      {
        title: "Payment Gateways",
        path: "/Masters/PaymentGateWay",
        access: rights?.["Masters"]?.["view"],
      },
      {
        title: "Country",
        path: "/Masters/Country/Country",
        access: rights?.["Masters"]?.["view"],
      },
       {
        title: "Sub Admin",
        path: "/Masters/SubAdmin/SubAdmin/",
        access: rights?.["Sub Admin"]?.["view"],
      },
     
      
    ].filter(e=>e),
  },
  role != "Distributor" && {
    title: "Settings",
    path: "/Settings/",
    icon: <img src={SettingIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    access: rights?.["Setting"]?.["view"],

    subNav: [
      
      {
        title: "About Us",
        path: "/Settings/AboutUs",
        access: rights?.["Setting"]?.["view"],

      },
      
      {
        title: "Privacy Policy",
        path: "/Settings/PrivacyPolicy",
        access: rights?.["Setting"]?.["view"],

      },
      {
        title: "Terms & Conditions",
        path: "/Settings/TermsConditions",
        access: rights?.["Setting"]?.["view"],

      },
      {
        title: "Refund Policy",
        path: "/Settings/RefundPolicy",
        access: rights?.["Setting"]?.["view"],

      },
     
    
    ].filter(e=>e),
  },
].filter(e=>e);}
